<template>
  <v-tooltip
    :color="`${color} darken-1`"
    style="z-index:5"
    max-width="500"
    class="custom-tooltip"
    content-class="custom-tooltip"
    top
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-icon
          :key="icon"
          :class="{ 'ml-1': index > 0 }"
          :small="!$vuetify.breakpoint.mobile"
          :color="color"
        >
          {{ icon }}
        </v-icon>
      </span>
    </template>
    <div class="font-weight-medium py-1">
      {{ text }}
    </div>
    <ul v-if="iconKey == 'pregnant' && pregnant && (drug.category >= 'C' || drug.category == '?')">
      <li>
        {{ $t('Category') }} {{ drug.category >= 'C' ? drug.category : $t('unknown') }}: {{ $t(`drug_info.pregnancy_${drug.category}`) }}
      </li>
    </ul>
    <ul v-if="iconKey == 'allergies' && drug_allergies.includes(drug.class_id)">
      <li
        v-for="allergy, i in allergies.filter(x => x.group.drug_classes.map(x => x.id).includes(drug.class_id)).map(x => x.name)"
        :key="i"
      >
        {{ allergy }}
      </li>
    </ul>
    <ul v-if="['breastfeeding_comment', 'breastfeeding_avoid'].includes(iconKey) && breastfeeding">
      <li>{{ drug.breastfeeding_use_comment }}</li>
    </ul>
    <ul v-if="iconKey == 'allergies' && drug_allergies.includes(drug.class_id)">
      <li
        v-for="allergy, i in allergies.filter(x => x.group.drug_classes.map(x => x.id).includes(drug.class_id)).map(x => x.name)"
        :key="i"
      >
        {{ allergy }}
      </li>
    </ul>
    <ul v-if="['resistance', 'resistance_disclaimer'].includes(iconKey) && resistances">
      <li
        v-for="resistance, i in resistances"
        :key="i"
      >
        {{ resistance.name[$i18n.locale] || resistance.name.en }}
        <ul v-if="drug.resistance.find(r => r.name.en === resistance.name.en)?.details?.disclaimer">
          <li>
            <i>
              {{ drug.resistance.find(r => r.name.en === resistance.name.en)?.details?.disclaimer }}
            </i>
          </li>
        </ul>
        <ul v-if="nihData[organism.name][resistance.name.en] && nihData[organism.name][resistance.name.en].frequency_class">
          <li>
            <i>
              {{
                $t('meds_matrix.nih', {
                  resistance: resistance.short_name ?? resistance.name[$i18n.locale],
                  percentage: $options.filters.percentage(nihData[organism.name][resistance.name.en].frequency_class),
                  total: $options.filters.numeral(nihData[organism.name][resistance.name.en].total),
                  organism: organism.name
                })
              }}
            </i>
          </li>
        </ul>
      </li>
    </ul>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    iconKey: {
      type: String,
      default: null,
    },
    organism: {
      type: Object,
      default: () => {},
    },
    drug: {
      type: Object,
      default: () => {},
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    resistance: {
      type: Array,
      default: () => ([]),
    },
    resistanceNames: {
      type: Array,
      default: () => ([]),
    },
    drugResistance: {
      type: Array,
      default: null,
    },
    pediatric: Boolean,
    pregnant: Boolean,
    breastfeeding: Boolean,
    text: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'far fa-minus',
    },
    index: {
      type: Number,
      default: 0,
    },
    nihData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    drug_allergies () {
      return this.allergies.filter(x => x.group).flatMap(x => x.group.drug_classes).map(x => x.id)
    },
    resistances () {
      return this.organism.relevant_resistances
        .filter((v, i, a) => a.findIndex(v2 => (v2.name.en === v.name.en)) === i)
        .filter(value => this.resistanceNames.includes(value.name.en))
        .filter(value => this.drug.resistance.map(r => r.name.en).includes(value.name.en))
    },
  },
}
</script>
<style>
.custom-tooltip {
    opacity: 0.95 !important;
}
</style>
<style scoped>
ul {
  margin-bottom: 0.4rem;
}
</style>
